import { useRecoilState } from 'recoil';
import { currentMarkerAtom } from '../../../state/atoms';
import dayjs from 'dayjs';
import InfoMarkerComponent from '../../../../Website/components/InfoMarkerComponent';
import { useState } from 'react';
import { QuakeStatus } from '../utils/enum';

const MARKER_COLORS = {
    RED: 'bg-red-500',
    ORANGE: 'bg-orange-400',
    YELLOW: 'bg-yellow-300'
};

function getQuakeBgClass(date) {
    const now = dayjs();
    const quakeDate = dayjs(date);
    let markerBgColor = '';

    if (quakeDate > now.clone().subtract(1, 'day')) {
        markerBgColor = MARKER_COLORS.RED;
    } else if (quakeDate > now.clone().subtract(2, 'day')) {
        markerBgColor = MARKER_COLORS.ORANGE;
    } else {
        markerBgColor = MARKER_COLORS.YELLOW;
    }

    return markerBgColor;
}

const QuakeMarker = ({ quake, selected, onActivate }) => {
    const markerBgClass = getQuakeBgClass(quake.datetime);
    const markerWidth = 0.5 + (quake.magnitude / 4);
    const markerHeight = markerWidth;
    const markerSizeStyle = { width: markerWidth + 'rem', height: markerHeight + 'rem' };

    const markerShapeClass = quake.status === QuakeStatus.AUTOMATIC ? 'rounded-sm border border-dashed border-gray-800' : 'rounded-full';
    const [showHoverTip, setShowHoverTip] = useState(false);

    const handleItemSelection = () => {
        onActivate(quake);
    };

    const handleDeselection = () => {
        onActivate(null);
    };

    const marker = () => (
        <div onClick={handleItemSelection}
             onMouseEnter={() => setShowHoverTip(true)}
             onMouseLeave={() => setShowHoverTip(false)}
             style={markerSizeStyle}
             className={`${selected ? 'z-20' : ''} ${markerBgClass} ${markerShapeClass} -translate-y-[50%] -translate-x-[50%] relative flex justify-center items-center hover:opacity-75 border-gray-400 border-solid border-[0.5px] `}
        >{quake.status === QuakeStatus.AUTOMATIC ? 'A' : ''}
        </div>
    );

    const markerEffect = () => (
        <div style={{ transform: `translate(${-markerWidth / 2}rem, ${-markerHeight / 2}rem)`}}>
            <div
                style={markerSizeStyle}
                className={`absolute animate-ping pointer-events-none ${selected ? 'z-50' : ''} ${markerBgClass} ${markerShapeClass} `}>
            </div>
        </div>
    );

    return (
        <div
            className='relative'
        >
            <div className='relative'>
                {selected ? markerEffect() : <></>}
                {marker()}
            </div>

            {showHoverTip && quake.status === QuakeStatus.AUTOMATIC &&
                <div
                    style={{ transform: 'translate(-50%, ' + (-markerHeight / 2) + 'rem)' }}
                    className={`absolute z-50 rounded-sm shadow-md bg-gray-200 text-gray-800 p-1 text-center`}>
                    Sismo Automático
                </div>
            }

            {selected && (
                <InfoMarkerComponent
                    style={{ transform: `translate(-50%, ${-markerHeight / 2}rem)` }}
                    className={`absolute z-20`}
                    quakeData={quake}
                    onClose={handleDeselection}
                />
            )}
        </div>
    );
};

export default QuakeMarker;
