import dayjs from 'dayjs';

export const formatDate = (date) => {
    return dayjs(date).format('MMM D, YYYY');
};

export const formatTime = (date) => {
    return dayjs(date).format('h:mm:ss a');
};

export const formatCoordinates = (coord1, coord2) => {
    return (
        <>
            <span className='text-gray-400'>(</span>
            {coord1}&deg;
            <span className='text-gray-400'>,</span>
            &nbsp;{coord2}&deg;
            <span className='text-gray-400'>)</span>
        </>
    );
};
