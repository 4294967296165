import { useEffect, useState } from 'react';
import { createQuake, updateQuake } from '../QuakeService';
import ValidationError from '../../../components/ValidationError';
import dayjs from 'dayjs';
import {
    QuakeCategoryOptions,
    DEFAULT_QUAKE_STATUS,
    QuakeStatusLabelMap,
    DEFAULT_QUAKE_CATEGORY
} from '../utils/enum';
import TimeField from '../../../components/TimeField';

const statusOptions = Object.keys(QuakeStatusLabelMap).map((status) => ({
    label: QuakeStatusLabelMap[status],
    value: status
}));

const unitOptions = [
    {
        label: 'Escala Local',
        value: 'Ml'
    },
    {
        label: 'Escala de Momento',
        value: 'Mw'
    },
    {
        label: 'Escala de Coda',
        value: 'Mc'
    }
];

export default function QuakeForm({ quake, onClose }) {
    const [unit, setUnit] = useState(quake?.unit || unitOptions[0].value);
    const [magnitude, setMagnitude] = useState(quake?.magnitude ?? '');
    const [latitude, setLatitude] = useState(quake?.latitude ?? '');
    const [longitude, setLongitude] = useState(quake?.longitude ?? '');
    const [depth, setDepth] = useState(quake?.depth ?? '');
    const [description, setDescription] = useState(quake?.description || '');
    const [place, setPlace] = useState(quake?.location || '');
    const [rms, setRms] = useState(quake?.rms ?? '');
    const [status, setStatus] = useState(quake?.status || DEFAULT_QUAKE_STATUS);
    const [category, setCategory] = useState(
        quake?.category?.toUpperCase() || DEFAULT_QUAKE_CATEGORY
    );
    const correctedDate = dayjs(quake?.datetime)
        .utc()
        .format('YYYY-MM-DD');
    const correctedTime = dayjs(quake?.datetime)
        .utc()
        .format('HH:mm:ss');
    const [date, setDate] = useState(correctedDate);
    const [time, setTime] = useState(correctedTime);
    const [errors, setErrors] = useState({});

    const onSaveHandler = async (event) => {
        event.preventDefault();
        if (time.length < 8) {
            setErrors({
                ...errors,
                time: ['La hora debe estar en formato HH:mm:ss']
            });
            return;
        }
        const payload = {
            unit,
            magnitude,
            latitude,
            longitude,
            depth,
            description: description || '',
            rms,
            location: place || '',
            category,
            datetime: dayjs(date + ' ' + time).utc(true).format('YYYY-MM-DDTHH:mm:ss'),
            status
        };

        try {
            if (quake?.id) {
                await updateQuake({ id: quake.id, ...payload });
            } else {
                await createQuake(payload);
            }
            onClose && onClose(true);
        } catch (err) {
            const { errors } = err.response.data;
            setErrors(errors);
            // show some notification or alert
        }
    };

    return (
        <form
            onSubmit={onSaveHandler}
            className='space-y-8 divide-y divide-gray-200'
        >
            <div className='space-y-8 divide-y divide-gray-200'>
                <div>
                    <div className='grid grid-cols-1 gap-y-6 gap-x-4 '>
                        <div className=''>
                            <label
                                htmlFor='magnitude'
                                className='block text-sm font-medium text-gray-700'
                            >
                                Magnitud
                            </label>
                            <div className='mt-1 flex flex flex-col gap-2 rounded-md'>
                                <input
                                    type='number'
                                    name='magnitude'
                                    id='magnitude'
                                    step='any'
                                    min='0'
                                    value={magnitude}
                                    onChange={(e) =>
                                        setMagnitude(e.target.value)
                                    }
                                    className='w-32'
                                />
                                <ValidationError errors={errors.magnitude} />
                            </div>
                        </div>

                        <div>
                            <label
                                htmlFor='unit'
                                className='block text-sm font-medium text-gray-700'
                            >
                                Unidad
                            </label>
                            <div className='mt-1 flex flex flex-col gap-3 rounded-md'>
                                <select
                                    id='unit'
                                    name='unit'
                                    value={unit}
                                    onChange={(e) => setUnit(e.target.value)}
                                    className='w-32'
                                >
                                    {unitOptions.map((unit) => (
                                        <option
                                            value={unit.value}
                                            key={unit.value}
                                        >
                                            {unit.value}
                                        </option>
                                    ))}
                                </select>
                                {errors.unit && (
                                    <ValidationError errors={errors.unit} />
                                )}
                            </div>
                        </div>

                        <div className=''>
                            <label
                                htmlFor='rms'
                                className='block text-sm font-medium text-gray-700'
                            >
                                RMS
                            </label>
                            <div className='mt-1 flex flex flex-col gap-3 rounded-md'>
                                <input
                                    type='number'
                                    name='rms'
                                    id='rms'
                                    step='any'
                                    min='0'
                                    value={rms}
                                    onChange={(e) => setRms(e.target.value)}
                                    className='w-32'
                                />
                                {errors.rms && (
                                    <ValidationError errors={errors.rms} />
                                )}
                            </div>
                        </div>

                        <div className=''>
                            <label
                                htmlFor='latitude'
                                className='block text-sm font-medium text-gray-700'
                            >
                                Latitud
                            </label>
                            <div className='mt-1 flex flex flex-col gap-3 rounded-md'>
                                <input
                                    type='number'
                                    name='latitude'
                                    id='latitude'
                                    step='any'
                                    min='-90'
                                    max='90'
                                    value={latitude}
                                    onChange={(e) =>
                                        setLatitude(e.target.value)
                                    }
                                    className='w-32'
                                />
                                {errors.latitude && (
                                    <ValidationError errors={errors.latitude} />
                                )}
                            </div>
                        </div>

                        <div className=''>
                            <label
                                htmlFor='longitude'
                                className='block text-sm font-medium text-gray-700'
                            >
                                Longitud
                            </label>
                            <div className='mt-1 flex flex flex-col gap-3 rounded-md'>
                                <input
                                    type='number'
                                    name='longitude'
                                    id='longitude'
                                    step='any'
                                    min='-180'
                                    max='180'
                                    value={longitude}
                                    onChange={(e) =>
                                        setLongitude(e.target.value)
                                    }
                                    className='w-32'
                                />
                                {errors.longitude && (
                                    <ValidationError
                                        errors={errors.longitude}
                                    />
                                )}
                            </div>
                        </div>

                        <div className=''>
                            <label
                                htmlFor='depth'
                                className='block text-sm font-medium text-gray-700'
                            >
                                Profundidad (KMs)
                            </label>
                            <div className='mt-1 flex flex flex-col gap-3 rounded-md'>
                                <input
                                    type='number'
                                    name='depth'
                                    id='depth'
                                    step='any'
                                    min='0'
                                    value={depth}
                                    onChange={(e) => setDepth(e.target.value)}
                                    className='w-32'
                                />
                                {errors.depth && (
                                    <ValidationError errors={errors.depth} />
                                )}
                            </div>
                        </div>

                        <div className=''>
                            <label
                                htmlFor='category'
                                className='block text-sm font-medium text-gray-700'
                            >
                                Categoría
                            </label>
                            <div className='mt-1 flex flex flex-col gap-3 rounded-md'>
                                <select
                                    id='category'
                                    name='category'
                                    value={category}
                                    onChange={(e) =>
                                        setCategory(e.target.value)
                                    }
                                    className='w-32'
                                >
                                    {QuakeCategoryOptions.map((cat) => (
                                        <option
                                            value={cat.value}
                                            key={cat.value}
                                        >
                                            {cat.label}
                                        </option>
                                    ))}
                                </select>
                                {errors.category && (
                                    <ValidationError errors={errors.category} />
                                )}
                            </div>
                        </div>

                        <div className=''>
                            <label
                                htmlFor='place'
                                className='block text-sm font-medium text-gray-700'
                            >
                                Lugar
                            </label>
                            <div className='mt-1 flex flex flex-col gap-3 rounded-md'>
                                <input
                                    type='text'
                                    name='place'
                                    id='place'
                                    value={place}
                                    onChange={(e) => setPlace(e.target.value)}
                                    className='w-full'
                                />
                                {errors.location && (
                                    <ValidationError errors={errors.location} />
                                )}
                            </div>
                        </div>

                        <div className=''>
                            <label
                                htmlFor='description'
                                className='block text-sm font-medium text-gray-700'
                            >
                                Descripción
                            </label>
                            <div className='mt-1 flex flex flex-col gap-3 rounded-md'>
                                <textarea
                                    name='description'
                                    id='description'
                                    value={description}
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                    className='w-full'
                                ></textarea>
                                {errors.description && (
                                    <ValidationError
                                        errors={errors.description}
                                    />
                                )}
                            </div>
                        </div>

                        <div className=''>
                            <label
                                htmlFor='status'
                                className='block text-sm font-medium text-gray-700'
                            >
                                Estado
                            </label>
                            <div className='mt-1 flex flex flex-col gap-3 rounded-md'>
                                <select
                                    name='status'
                                    id='status'
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                    className='w-full'
                                >
                                    {statusOptions.map((option) => (
                                        <option
                                            value={option.value}
                                            key={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                {errors.status && (
                                    <ValidationError errors={errors.status} />
                                )}
                            </div>
                        </div>

                        <div className=''>
                            <label
                                htmlFor='datetime'
                                className='block text-sm font-medium text-gray-700'
                            >
                                Fecha y Hora (UTC)
                            </label>
                            <div className='mt-1 flex flex flex-col gap-3 rounded-md'>
                                <input
                                    type='date'
                                    name='datetime'
                                    id='datetime'
                                    value={date}
                                    onChange={(e) =>
                                        setDate(e.target.value)
                                    }
                                    className='w-full'
                                />
                                <TimeField
                                    name='datetime-time'
                                    id='datetime-time'
                                    value={time}
                                    onChange={value => setTime(value)}
                                    className='w-full'
                                />
                                {errors.date && (
                                    <ValidationError errors={errors.date} />
                                )}
                                {errors.time && (
                                    <ValidationError errors={errors.time} />
                                )}
                                {errors.datetime && (
                                    <ValidationError errors={errors.datetime} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-5'>
                <div className='flex justify-end'>
                    <button
                        type='button'
                        className='rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                        onClick={() => onClose && onClose()}
                    >
                        Cancelar
                    </button>
                    <button
                        type='submit'
                        className='ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    >
                        Guardar
                    </button>
                </div>
            </div>
        </form>
    );
}
