import React, { useEffect, useState } from 'react';
import { LinkIcon, PencilIcon, XIcon } from '@heroicons/react/solid';
import { getUrl } from '../../../shared/urls';
import { downloadAsCsv } from '../../modules/quakes/QuakeService';
import { useSearchParams } from 'react-router-dom';
import { getQuakesFilters } from '../../modules/quakes/utils/filter';
import CsvDownloadModal from './CsvDownloadModal';

export const FilterTopActions = (props) => {
    const {
        filters,
        onOpenEditor,
        onCloseEditor,
        publicPath,
        isEditing = false,
        withCsvExport = false
    } = props;
    const [shareLink, setShareLink] = useState('');
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const baseUrl = import.meta.env.VITE_SITEURL;
        const publicLink = baseUrl + publicPath + window.location.search;
        setShareLink(publicLink);
    }, [filters]);

    const copyLinkToClipboard = async (e) => {
        e.preventDefault();
        try {
            const textToCopy = getUrl(publicPath, true);
            if ('clipboard' in navigator) {
                return await navigator.clipboard.writeText(textToCopy);
            } else {
                return document.execCommand('copy', true, textToCopy);
            }
        } catch (e) {
            console.error(e);
        }

        return false;
    };

    const handleDownloadCsvClick = async (e) => {
        e.preventDefault();
        const params = getQuakesFilters(searchParams);
        await downloadAsCsv(params);
    };

    const openFilterEditor = () => {
        onOpenEditor && onOpenEditor(true);
    };

    const closeFilterEditor = () => {
        onCloseEditor && onCloseEditor(false);
    };

    return (
        <div className='flex space-x-3  items-center'>
            <a href={shareLink || '#'}
               className='flex space-x-1 text-sm text-brand-blue items-center group hover:cursor-pointer'
               onClick={copyLinkToClipboard}
               title='Copiar enlace'
            >
                <LinkIcon className='group-hover:opacity-50 w-4 h-4' />
                <span className='hidden sm:inline'>Copiar <span className='sm:hidden lg:inline'>enlace</span></span>
            </a>
            {withCsvExport &&
                <CsvDownloadModal onDownloadRequest={handleDownloadCsvClick} />
            }
            {!isEditing &&
                <button
                    title="Agregar Filtro"
                    type='button'
                    className='flex space-x-1 text-sm text-brand-blue group items-center hover:cursor-pointer'
                    onClick={openFilterEditor}>
                    <PencilIcon className='group-hover:opacity-50 w-4 h-4' />
                    <span className='hidden sm:inline'>Agregar Filtro</span>
                </button>
            }
            {isEditing &&
                <div className='flex space-x-1 text-sm group text-brand-blue items-center hover:cursor-pointer'
                     onClick={closeFilterEditor}>
                    <XIcon className='group-hover:opacity-50 w-4 h-4' />
                    <span className='hidden sm:inline'>Ocultar formulario de filtrado</span>
                </div>
            }
        </div>
    );
};
