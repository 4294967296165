export const QuakeStatusLabelMap = {
    automatic: 'Automático',
    provisional: 'Provisional',
    revised: 'Revisado',
};

export enum QuakeStatus {
    AUTOMATIC = 'automatic',
    PROVISIONAL= 'provisional',
    REVISED = 'revised',
}

export const QuakeCategoryOptions = [
    {
        label: 'Local',
        value: 'L'
    },
    {
        label: 'Regional',
        value: 'R'
    },
    {
        label: 'Distante',
        value: 'D'
    }
];

export const DEFAULT_QUAKE_STATUS = QuakeStatus.PROVISIONAL;
export const DEFAULT_QUAKE_CATEGORY = QuakeCategoryOptions[0].value;
