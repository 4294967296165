import {
    formatCoordinates,
    formatDate,
    formatTime,
} from '../../../utils/format';
import { useRecoilValue } from 'recoil';
import { userRoleFlagSelector } from '../../../state/atoms';
import { QuakeStatusLabelMap } from '../utils/enum';
import DeleteQuake from './DeleteQuake';
import { PencilIcon } from '@heroicons/react/outline';
import TableTh from '../../../components/TableTh';
import { translations } from '../../../utils/filters';

export default function QuakeTable(props) {
    const { isAdmin } = useRecoilValue(userRoleFlagSelector);
    const {
        quakes,
        editQuake,
        onSelect,
        onDeleteQuake,
        selectedQuake,
        onSort,
        orderBy,
        orderDirection,
    } = props;
    const items = quakes || [];

    const editQuakeHandler = (e, quake) => {
        e.preventDefault();
        e.stopPropagation();
        editQuake && editQuake(quake);
    };

    const handleRowClick = (e, quake) => {
        e.preventDefault();
        onSelect && onSelect(quake);
    };

    const getTrBg = (quake, quakeIndex) => {
        if (selectedQuake?.id === quake.id) {
            return 'bg-gray-200 border border-solid border-gray-300 border-l';
        }
        return quakeIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50';
    };

    const sortBy = (fieldName) => {
        onSort &&
            onSort({
                by: fieldName,
                direction: orderDirection === 'desc' ? 'asc' : 'desc',
            });
    };

    const handleRemoveQuake = (params) => {
        onDeleteQuake && onDeleteQuake(params);
    };

    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle ">
                    <div className="max-h-192 overflow-hidden overflow-y-scroll border-2 border-gray-200 shadow sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <TableTh
                                        onSort={sortBy}
                                        name="id"
                                        title="ID"
                                        sorting={{ orderBy, orderDirection }}
                                    />
                                    <TableTh
                                        onSort={sortBy}
                                        name="datetime"
                                        title="Fecha"
                                        supTitle={'Local'}
                                        sorting={{ orderBy, orderDirection }}
                                    />
                                    <TableTh
                                        onSort={sortBy}
                                        name="magnitude"
                                        title="Magnitud"
                                        sorting={{ orderBy, orderDirection }}
                                    />
                                    <TableTh
                                        onSort={sortBy}
                                        name="rms"
                                        title="RMS"
                                        className=""
                                        sorting={{ orderBy, orderDirection }}
                                    />
                                    <TableTh
                                        onSort={sortBy}
                                        name="depth"
                                        title="Profundidad"
                                        sorting={{ orderBy, orderDirection }}
                                    />
                                    <TableTh
                                        onSort={sortBy}
                                        name="status"
                                        title="estado"
                                        sorting={{ orderBy, orderDirection }}
                                    />
                                    <TableTh title="Coordenadas" />
                                    <TableTh title="Descripción" />
                                    {isAdmin && <TableTh />}
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((quake, quakeIndex) => (
                                    <tr
                                        key={quake.id}
                                        className={getTrBg(quake, quakeIndex)}
                                        onClick={(e) =>
                                            handleRowClick(e, quake)
                                        }
                                    >
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {quake.id}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <div className="flex flex-col">
                                                <span className="text-xs font-bold uppercase">
                                                    {formatTime(quake.datetime)}
                                                </span>
                                                <span>
                                                    {formatDate(quake.datetime)}
                                                </span>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {quake.magnitude}{' '}
                                            <sub>{quake.unit}</sub>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {quake.rms}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {quake.depth} <sub>Km(s)</sub>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {
                                                translations[
                                                    QuakeStatusLabelMap[
                                                        quake.status.toLowerCase()
                                                    ] ||
                                                        quake.status.toLowerCase()
                                                ]
                                            }
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {formatCoordinates(
                                                quake.latitude,
                                                quake.longitude
                                            )}
                                        </td>
                                        <td className=" max-w-xs truncate whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                                            {quake.description}
                                            <span className="font-light">
                                                {quake.description &&
                                                quake.location
                                                    ? ' | '
                                                    : ''}
                                            </span>
                                            {quake.location}
                                        </td>
                                        {isAdmin && (
                                            <td className="space-x-1 whitespace-nowrap px-3 py-4 text-right text-sm font-medium">
                                                <div className="flex items-center space-x-1">
                                                    <a
                                                        onClick={(e) =>
                                                            editQuakeHandler(
                                                                e,
                                                                quake
                                                            )
                                                        }
                                                        className="cursor-pointer rounded-full p-2 text-brand-blue opacity-80 hover:bg-gray-100 hover:opacity-100 active:bg-brand-blue active:text-gray-100"
                                                        title={
                                                            'Editar Sismo ID=' +
                                                            quake.id
                                                        }
                                                    >
                                                        <PencilIcon className="h-4 w-4" />
                                                    </a>
                                                    <DeleteQuake
                                                        quake={quake}
                                                        onConfirm={
                                                            handleRemoveQuake
                                                        }
                                                    />
                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
