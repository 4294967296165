import React from 'react';
import dayjs from 'dayjs';
import { WiEarthquake } from 'react-icons/wi';
import { BsArrowBarUp } from 'react-icons/bs';
import { QuakeCategoryOptions } from '../../Panel/modules/quakes/utils/enum';
import { formatCoordinates } from '../../Panel/utils/format';

const InfoMarkerComponent = (props) => {
    const {
        description,
        magnitude,
        datetime,
        latitude,
        longitude,
        category,
        depth,
        location,
        rms,
        status,
        unit,
    } = props.quakeData;

    return (
        <div
            style={props.style}
            className={props.className + ' flex flex-col items-center'}
        >
            <div className="relative h-0 w-0 border-r-[10px] border-l-[10px] border-b-[16px] border-t-0 border-solid border-white border-l-transparent border-r-transparent"></div>
            <div
                className={
                    'infoMarker flex flex w-96 flex-wrap justify-center justify-center rounded-lg bg-white py-4 px-10 shadow-md'
                }
            >
                <div className="mb-3 grid w-full grid-cols-3 items-start gap-x-3 border-b-2 border-dashed pb-3">
                    <div className="item flex flex-wrap items-center justify-center py-2">
                        <div className="flex w-full items-center justify-start text-gray-500">
                            <WiEarthquake
                                className="text-base text-gray-500"
                                // color={colorQuake}
                            />
                            Magnitud
                        </div>
                        <div className="flex w-full items-center text-center text-3xl font-bold text-brand-blue">
                            {magnitude.toFixed(1)}
                            <sub className="text-base">{unit}</sub>
                        </div>
                    </div>
                    <div className="item flex flex-wrap items-center justify-center py-2">
                        <div className="flex w-full items-center justify-start text-gray-500">
                            <BsArrowBarUp
                                className="text-base text-gray-500"
                                // color={colorQuake}
                            />
                            Profundidad
                        </div>
                        <div className="flex w-full items-center text-center text-3xl font-bold text-brand-brown">
                            {depth}
                            <sub className="text-base">KMs</sub>
                        </div>
                    </div>
                    <div className="item flex flex-wrap items-center justify-center py-2">
                        <div className="flex w-full items-center justify-center text-center text-gray-500">
                            RMS
                        </div>
                        <div className="flex w-full items-center justify-center text-center text-3xl font-bold text-brand-brown">
                            {rms.toFixed(1)}
                        </div>
                    </div>
                </div>
                <div className="grid w-full grid-cols-2 items-start gap-x-3">
                    <div className="item flex flex-wrap items-center justify-center py-2">
                        <div className="flex w-full items-center justify-start text-gray-500">
                            Fecha (Local)
                        </div>
                        <div className="w-full text-left text-base text-gray-700">
                            {dayjs(datetime).format('h:mm A')}
                        </div>
                        <div className="w-full text-left text-base text-gray-700">
                            {dayjs(datetime).format('DD/MM/YYYY')}
                        </div>
                    </div>
                    <div className="item flex flex-wrap items-center justify-center py-2">
                        <div className="flex w-full items-center justify-start text-gray-500">
                            Lugar
                        </div>
                        <div className="w-full text-left text-base text-gray-600">
                            {location || 'Desconocido'}
                        </div>
                    </div>
                    <div className="item flex flex-wrap items-center justify-center py-2">
                        <div className="flex w-full items-center justify-start text-gray-500">
                            Coordenadas
                        </div>
                        <div className="w-full text-left text-base text-gray-600">
                            {formatCoordinates(latitude, longitude)}
                        </div>
                    </div>
                    <div className="item flex flex-wrap items-center justify-center py-2">
                        <div className="flex w-full items-center justify-start text-gray-500">
                            Categoría
                        </div>
                        <div className="w-full text-left text-base text-gray-600">
                            {QuakeCategoryOptions.find(
                                (c) => c.value === category.toUpperCase()
                            )?.label || ''}
                        </div>
                    </div>
                </div>
                <button
                    onClick={props.onClose}
                    className="infoMarker-close absolute right-2 top-2 cursor-pointer rounded-full px-3 py-3 text-base text-brand-blue"
                >
                    X
                </button>
            </div>
        </div>
    );
};

export default InfoMarkerComponent;
